import React, { useState } from 'react';
import { auth, db } from '../backEnd/firebase'; // Ensure the correct path to your Firebase config
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';

const UserForm = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [state, setState] = useState('');
  const [userGrade, setUserGrade] = useState('');
  const [isApproved, setIsApproved] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Convert email to lowercase to maintain consistency
      const emailLowerCase = email.toLowerCase();
  
      // Create user with email and password
      const res = await createUserWithEmailAndPassword(auth, emailLowerCase, password);
      if (!res) throw new Error('User not created');
  
      const uid = res.user.uid;
  
      // Store user data in Firestore with structure
      await setDoc(doc(db, 'usersApp', uid), {
        id: uid, // Store the UID as id
        fname,
        lname,
        email: emailLowerCase,
        phone,
        company: selectedCompany,
        companyCode,
        state,
        userGrade,
        isApproved,
        password, // Consider removing this in production to ensure security
        department: 'Charlotte', // Assuming department is constant, otherwise, add a field for it in your form
        createdAt: new Date(),
      });
  
      // Display success message
      setSuccessMessage('User created and data stored successfully!');
      setErrorMessage('');
  
      // Clear form fields
      setFname('');
      setLname('');
      setEmail('');
      setPhone('');
      setPassword('');
      setSelectedCompany('');
      setCompanyCode('');
      setState('');
      setUserGrade('');
      setIsApproved('');
    } catch (error) {
      console.error('Error creating user:', error);
      setErrorMessage('Error creating user. Please try again.');
      setSuccessMessage('');
    }
  };
  

  return (
    <div>
      <h3>Create User</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            value={lname}
            onChange={(e) => setLname(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>* Phone :</label>
          <input
            type="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Company:</label>
          <input
            type="text"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Company Code:</label>
          <input
            type="text"
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
            required
          />
        </div>
        <div>
          <label>State:</label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          />
        </div>
        <div>
          <label>User Grade:</label>
          <input
            type="text"
            value={userGrade}
            onChange={(e) => setUserGrade(e.target.value)}
          />
        </div>
        <div>
          <label>Is Approved:</label>
          <input
            type="text"
            value={isApproved}
            onChange={(e) => setIsApproved(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create UseSr</button>
      </form>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default UserForm;
