import React, { useState } from 'react';
import { auth, db } from '../backEnd/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, query, where, getDocs, collection } from 'firebase/firestore';
import '../Styles/OwnerDashboard.css';

const NewUserForm = ({ companyName, companyCode }) => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(companyName);
  const [companyCodeState, setCompanyCode] = useState(companyCode);
  const [state, setState] = useState('');
  const [userGrade, setUserGrade] = useState('technician');
  const [isApproved, setIsApproved] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsPending(false);
      return;
    }
    if (!fname || !lname || !email || !password || !confirmPassword || !state) {
      setError('All fields are required');
      setIsPending(false);
      return;
    }

    try {
      const q = query(
        collection(db, 'Clients'),
        where('companyCode', '==', companyCodeState)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setError('Invalid company code');
        setIsPending(false);
        return;
      }

      const emailLowerCase = email.toLowerCase();

      const res = await createUserWithEmailAndPassword(auth, emailLowerCase, password);
      if (!res) throw new Error('User not created');

      const uid = res.user.uid;

      await setDoc(doc(db, 'usersApp', uid), {
        fname,
        lname,
        email: emailLowerCase,
        company: selectedCompany,
        companyCode: companyCodeState,
        state,
        userGrade,
        isApproved,
        createdAt: new Date(),
      });

      setSuccessMessage('User created and data stored successfully!');
      setErrorMessage('');
      setTimeout(() => setSuccessMessage(''), 5000);

      setFname('');
      setLname('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setSelectedCompany('');
      setCompanyCode('');
      setState('');
      setUserGrade('');
      setIsApproved('');
    } catch (error) {
      console.error('Error creating user:', error);
      setErrorMessage('Error creating user. Please try again.');
      setSuccessMessage('');
      setError(error.code === 'auth/email-already-in-use' ? 'This email is already registered, use a different email' : error.message);
    } finally {
      setIsPending(false);
    }
  };

  const stateOptions = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 
    'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 
    'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 
    'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  return (
    <div className="form-container">
      <h3>Add New Technician</h3>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name:</label>
          <input
            type="text"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Last Name:</label>
          <input
            type="text"
            value={lname}
            onChange={(e) => setLname(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="button" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Company:</label>
          <input
            type="text"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Company Code:</label>
          <input
            type="text"
            value={companyCodeState}
            onChange={(e) => setCompanyCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>State:</label>
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          >
            <option value="">Select State</option>
            {stateOptions.map((stateName) => (
              <option key={stateName} value={stateName}>
                {stateName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Is Approved:</label>
          <select
            value={isApproved}
            onChange={(e) => setIsApproved(e.target.value)}
            required
          >
            
            <option value="YES">YES</option>
            <option value="NO">NO</option>
          </select>
        </div>
        <button type="submit" disabled={isPending}>
          {isPending ? 'Creating User...' : 'Create User'}
        </button>
      </form>
    </div>
  );
};

export default NewUserForm;
