import React, { useState, useEffect } from 'react';
import { collection, getDocs, setDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../backEnd/firebase'; // Ensure the correct path to firebase.js
import '../Styles/OwnerDashboard.css'; // Import the CSS file
const AddSubcompany = () => {
  const [subcompanyName, setSubcompanyName] = useState('');
  const [manager, setManager] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subcompanies, setSubcompanies] = useState([]);

  // Fetch subcompanies from Firestore on component mount
  const fetchSubcompanies = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'subcompany'));
      const subcompanyList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubcompanies(subcompanyList);
    } catch (error) {
      console.error('Error fetching subcompanies:', error);
    }
  };

  // Call fetchSubcompanies when the component is mounted
  useEffect(() => {
    fetchSubcompanies();
  }, []);

  // Handle form submission to add new subcompany
  const handleSubmit = async (e) => {
    e.preventDefault();

    const ownerName = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
    const companyName = localStorage.getItem('company');
    const companyCode = localStorage.getItem('companyCode');

    const newSubcompany = {
      subcompanyName,
      manager,
      email,
      phone,
      ownerName,
      companyName,
      companyCode,
      createdAt: new Date(),
    };

    try {
      await setDoc(doc(db, 'subcompany', subcompanyName), newSubcompany); // Ensure this is correct
      alert('Subcompany added successfully!');
      setSubcompanyName('');
      setManager('');
      setEmail('');
      setPhone('');
      // Reload subcompanies after adding
      fetchSubcompanies();
    } catch (error) {
      console.error('Error adding subcompany:', error);
      alert('Failed to add subcompany. Please try again.');
    }
  };

  // Handle editing of subcompany data
  const handleEdit = async (subcompanyId) => {
    const updatedManager = prompt('Enter new manager name');
    const updatedEmail = prompt('Enter new email');
    const updatedPhone = prompt('Enter new phone number');

    if (updatedManager && updatedEmail && updatedPhone) {
      try {
        const subcompanyRef = doc(db, 'subcompany', subcompanyId);
        await updateDoc(subcompanyRef, {
          manager: updatedManager,
          email: updatedEmail,
          phone: updatedPhone,
        });
        alert('Subcompany updated successfully!');
        // Reload subcompanies after updating
        fetchSubcompanies();
      } catch (error) {
        console.error('Error updating subcompany:', error);
        alert('Failed to update subcompany.');
      }
    }
  };

  return (
    <div>
      <h2>Add New Department (Group Chat)</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Group Name:</label>
          <input
            type="text"
            value={subcompanyName}
            onChange={(e) => setSubcompanyName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Manager:</label>
          <input
            type="text"
            value={manager}
            onChange={(e) => setManager(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create Department (Group Chat)</button>
      </form>

      <h2>Department (Group Chat) List</h2>
      <table className="subcompany-table">
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Manager</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subcompanies.map((subcompany) => (
            <tr key={subcompany.id}>
              <td>{subcompany.subcompanyName}</td>
              <td>{subcompany.manager}</td>
              <td>{subcompany.email}</td>
              <td>{subcompany.phone}</td>
              <td>
                <button onClick={() => handleEdit(subcompany.id)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddSubcompany;
