import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import '../Styles/OwnerDashboard.css';
import { useNavigate } from 'react-router-dom';

function ListTechPerCompany() {
    const [clients, setClients] = useState([]);
    const [editClientId, setEditClientId] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [subcompanies, setSubcompanies] = useState([]);
    const navigate = useNavigate();
    const companyName = localStorage.getItem('company');

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const clientsCollection = query(
                    collection(db, 'usersApp'),
                    where('company', '==', companyName)
                );
                const querySnapshot = await getDocs(clientsCollection);
                const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setClients(data);
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        };
        fetchClients();
    }, [companyName]);

    useEffect(() => {
        const fetchSubcompanies = async () => {
            try {
                const subcompaniesCollection = query(
                    collection(db, 'subcompany'),
                    where('companyName', '==', companyName)
                );
                const querySnapshot = await getDocs(subcompaniesCollection);
                const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setSubcompanies(data);
            } catch (error) {
                console.error("Error fetching subcompanies:", error);
            }
        };
        fetchSubcompanies();
    }, [companyName]);

    const handleEditClick = (client) => {
        setEditClientId(client.id);
        setEditFormData(client);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({ ...editFormData, [name]: value });
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        const clientDoc = doc(db, 'usersApp', editClientId);
        try {
            await updateDoc(clientDoc, editFormData);
            setClients(prevClients =>
                prevClients.map(client =>
                    client.id === editClientId ? { ...client, ...editFormData } : client
                )
            );
            setEditClientId(null);
            alert("Client information updated successfully!");
        } catch (error) {
            console.error("Error updating client:", error);
            alert("Error updating client information. Please try again.");
        }
    };

    const addClient = () => {
        navigate('/NewUserForm');
    };

    return (
        <div className="MainPage">
            <h3>List of Technicians</h3>
            <table>
                <thead>
                    <tr>
                        <th>Company Code</th>
                        <th>Created At</th>
                        <th>Department</th>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>State</th>
                        <th>User Grade</th>
                        <th>Is Approved</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map(client => (
                        <tr key={client.id}>
                            <td>{client.companyCode}</td>
                            <td>{client.createdAt?.toDate()?.toLocaleString()}</td>
                            <td>{client.department}</td>
                            <td>{client.email}</td>
                            <td>{client.fname}</td>
                            <td>{client.lname}</td>
                            <td>{client.state}</td>
                            <td>{client.userGrade}</td>
                            <td>{client.isApproved}</td>
                            <td>
                                <button onClick={() => handleEditClick(client)}>Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editClientId && (
                <form onSubmit={handleUpdateSubmit}>
                    <h2>Edit Client</h2>
                    <button type="button" onClick={addClient}>Add Client</button>
                    <input
                        type="text"
                        name="companyCode"
                        value={editFormData.companyCode}
                        onChange={handleInputChange}
                        required
                    />
                    <select
                        name="department"
                        value={editFormData.department}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Department</option>
                        {subcompanies.map(sub => (
                            <option key={sub.id} value={sub.subcompanyName}>{sub.subcompanyName}</option>
                        ))}
                    </select>
                    <input
                        type="email"
                        name="email"
                        value={editFormData.email}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="text"
                        name="fname"
                        value={editFormData.fname}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="text"
                        name="lname"
                        value={editFormData.lname}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type="text"
                        name="state"
                        value={editFormData.state}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="userGrade"
                        value={editFormData.userGrade}
                        onChange={handleInputChange}
                        required
                    />
                    <select
                        name="isApproved"
                        value={editFormData.isApproved}
                        onChange={handleInputChange}
                    >
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                    </select>
                    <button type="submit">Update Client</button>
                </form>
            )}
        </div>
    );
}

export default ListTechPerCompany;
