// NewUserForm.js
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../backEnd/firebase'; // Ensure firebase is correctly set up
import '../Styles/OwnerDashboard.css'; // Import the CSS file
const NewUserForm = ({ closeForm, companyName, onClientAdded }) => {
    const [formData, setFormData] = useState({
        companyCode: '',
        department: '',
        email: '',
        fname: '',
        lname: '',
        state: '',
        userGrade: '',
        password: '',
        isApproved: 'NO',
        company: companyName,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const newDoc = await addDoc(collection(db, 'usersApp'), formData);
            onClientAdded({ ...formData, id: newDoc.id }); // Add new client to the list
            closeForm(); // Close the form after submission
            alert("New client added successfully!");
        } catch (error) {
            console.error("Error adding new client:", error);
            alert("Error adding new client. Please try again.");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
        <h2>Add New Owner</h2>
        <table className="form-table">
          <tbody>
            <tr>
              <td><label htmlFor="companyCode">Company Code</label></td>
              <td>
                <input
                  type="text"
                  name="companyCode"
                  id="companyCode"
                  placeholder="Company Code"
                  value={formData.companyCode}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="department">Department</label></td>
              <td>
                <input
                  type="text"
                  name="department"
                  id="department"
                  placeholder="Department"
                  value={formData.department}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="email">Email</label></td>
              <td>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="fname">First Name</label></td>
              <td>
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  placeholder="First Name"
                  value={formData.fname}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="lname">Last Name</label></td>
              <td>
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  placeholder="Last Name"
                  value={formData.lname}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="state">State</label></td>
              <td>
                <input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="userGrade">User Grade</label></td>
              <td>
                <input
                  type="text"
                  name="userGrade"
                  id="userGrade"
                  placeholder="User Grade"
                  value={formData.userGrade}
                  onChange={handleInputChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="password">Password</label></td>
              <td>
                <input
                  type="text"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td><label htmlFor="isApproved">Is Approved</label></td>
              <td>
                <select
                  name="isApproved"
                  id="isApproved"
                  value={formData.isApproved}
                  onChange={handleInputChange}
                >
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="form-actions">
          <button type="submit">Add Client</button>
          <button type="button" onClick={closeForm}>Cancel</button>
        </div>
      </form>
      
    );
};

export default NewUserForm;
