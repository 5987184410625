import React, { useState } from 'react';
import './App.css';
import Welcome from './components/Welcome';
import ClientProfile from './components/ClientProfile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import OwnerDashboard from './components/OwnerDashboard';
import OwnerLogin from './components/OwnerLogin';
import NewUserForm from './components/ClientProfile';
import UserForm from './components/UserForm';

function MainLayout({ setCompanyName }) {
  return (
    <div className='MainLayout'>
      <div>
        <Welcome />
      </div>
    </div>
  );
}

function App() {
  const [companyName, setCompanyName] = useState(''); // State to store the company name

  const clearUserData = () => {
    setCompanyName(''); // Clear company name on logout
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout setCompanyName={setCompanyName} />} />
        <Route path="/OwnerDashboard" element={<OwnerDashboard companyName={companyName} clearUserData={clearUserData} />} />
        <Route path="/client-profile" element={<ClientProfile />} />
        <Route path="/OwnerLogin" element={<OwnerLogin />} />
        <Route path="/NewUserForm" element={<NewUserForm />} />
        <Route path="/UserForm" element={<UserForm />} />
      </Routes>
    </Router>
  );
}

export default App;
