// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import for Firebase Authentication

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNJQ5rKci64Ni1UFym6QSgwHDhWMyQTMc",
  authDomain: "techtracknative.firebaseapp.com",
  databaseURL: "https://techtracknative-default-rtdb.firebaseio.com",
  projectId: "techtracknative",
  storageBucket: "techtracknative.appspot.com",
  messagingSenderId: "749519080715",
  appId: "1:749519080715:web:f0689782d88552dc33660e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize auth

// Export the initialized instances
export { app, analytics, db, auth }; // Add auth to the exports
