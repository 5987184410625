import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import ClientProfile from './ClientProfile';
import ListTechPerCompany from './ListTechPerCompany';
import AddSubcompany from './AddSubcompany';
import NewUserForm from './NewUserForm';
import Inventory from './Inventory';
import '../Styles/OwnerDashboard.css'; // Import the CSS file

const OwnerDashboard = () => {
  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState('users');
  const [subcompanies, setSubcompanies] = useState([]);

  const companyName = localStorage.getItem('company');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const companyCode = localStorage.getItem('companyCode');

  useEffect(() => {
    const fetchSubcompanies = async () => {
      try {
        const q = query(collection(db, 'subcompany'), where('companyName', '==', companyName));
        const querySnapshot = await getDocs(q);
        const subcompanyList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubcompanies(subcompanyList);
      } catch (error) {
        console.error('Error fetching subcompanies:', error);
      }
    };

    fetchSubcompanies();
  }, [companyName]);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="dashboard">

      <div className='topDash'>
        <div className = "menu">Owner Dashboard</div>
        <div className = "menu">Welcome, {firstName} {lastName}</div>
        <div className='code'>Company Code: {companyCode}</div>
        
        <div className = "menu">Your company name: {companyName}</div>
        <button onClick={handleLogout}>Logout</button> <br></br>
       
       
      </div>
     

      <aside className="dashboard-aside">
        <h3>Dashboard Menu</h3>
       
        <ul>
          <li onClick={() => setCurrentMenu('users')}>List of Your technicians</li>
          <li onClick={() => setCurrentMenu('NewTech')}>Add New Tech</li>
          <li onClick={() => setCurrentMenu('subcompanies')}>Department (Group Chat)</li>
         
          <li onClick={() => setCurrentMenu('inventory')}>Inventory</li>
        
          <li onClick={() => setCurrentMenu('settings')}>Settings</li></ul>
      </aside>
<div className='result'>
      {currentMenu === 'users' && <ListTechPerCompany />}
      {currentMenu === 'settings' && <ClientProfile />}
      {currentMenu === 'subcompanies' && <AddSubcompany />}        
      {currentMenu === 'NewTech' && <NewUserForm companyName={companyName} companyCode={companyCode} />}
      {currentMenu === 'inventory' && <Inventory companyName={companyName} />}

    </div>
    </div>
  );
};

export default OwnerDashboard;
