import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import { useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword'; // Import the ForgotPassword component

const OwnerLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false); // State to toggle Forgot Password form
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const q = query(
        collection(db, 'Clients'),
        where('email', '==', email),
        where('password', '==', password)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0].data();
        const companyName = userDoc.companyName;
        const firstName = userDoc.firstname;
        const lastName = userDoc.lastname;
        const companyCode = userDoc.companyCode;

        localStorage.setItem('company', companyName);
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('companyCode', companyCode);

        alert('Valid credentials');
        navigate('/OwnerDashboard');
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Error checking credentials:', error);
      alert('Error during login. Please try again.');
    }
  };

  return (
    <div className="login">
      <h2>Admin Login</h2>
      {showForgotPassword ? (
        <ForgotPassword /> // Render ForgotPassword component when user clicks "Forgot Password"
      ) : (
        <form className="formLogin" onSubmit={handleLoginSubmit}>
          <label>
            Email:
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit">Login</button>
          <button
            type="button"
            onClick={() => setShowForgotPassword(true)} // Set state to show Forgot Password form
          >
            Forgot Password?
          </button>
        </form>
      )}
    </div>
  );
};

export default OwnerLogin;
