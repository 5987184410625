import React, { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import '../Styles/general.css';
import { useNavigate } from 'react-router-dom';
import OwnerLogin from './OwnerLogin'; // Import the OwnerLogin component
import image2 from '../images/image8.png';
import image5 from '../images/image1.png';
import jobdash from '../images/jobdash.png';
import Newjob from '../images/NewJob.png';
import JobSum from '../images/jobSumarry.png';
import chat from '../images/chat.png';
import invent1 from '../images/invent1.png';
import invent2 from '../images/invent2.png';
import inventDash from '../images/inventDash.png';
import equipInstalled from '../images/equipInstalled.png';
import tech from '../images/image2.png'
function Welcome() {
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        companyName: '',
        companyCode: '',
        size: '',
        date: '',
        state: '',
        occupation: '',
        message: '',
        password: '',
        confirmPassword: ''
    });
    const [consent, setConsent] = useState(false);
    const [existingCompanyNames, setExistingCompanyNames] = useState([]);
    const navigate = useNavigate();

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];
    const companySizes = ['1-10', '11-30', '31-60', '61-100', '100+'];

    useEffect(() => {
        const fetchExistingCompanyNames = async () => {
            const snapshot = await getDocs(collection(db, 'Clients'));
            const companyNames = snapshot.docs.map(doc => doc.data().companyName);
            setExistingCompanyNames(companyNames);
        };

        fetchExistingCompanyNames();
    }, []);

    const generateCompanyCode = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        let code;

        do {
            const randomLetters = Array.from({ length: 3 }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
            const randomNumbers = Array.from({ length: 3 }, () => numbers[Math.floor(Math.random() * numbers.length)]).join('');
            code = `${randomLetters}${randomNumbers}`;
        } while (existingCompanyNames.includes(code));

        return code;
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleConsentChange = (e) => {
        setConsent(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!consent) {
            alert('Please agree to receive email communications before submitting.');
            return;
        }

        if (existingCompanyNames.includes(form.companyName)) {
            alert('This company name already exists. Please use a different name.');
            return;
        }

        if (form.password !== form.confirmPassword) {
            alert('Passwords do not match. Please check and try again.');
            return;
        }

        const uniqueCompanyCode = generateCompanyCode();
        const currentDate = new Date().toISOString();

        try {
            const docRef = doc(db, 'Clients', form.companyName); // Create a document reference with `email` as ID
            await setDoc(docRef, {
                ...form,
                date: currentDate,
                companyCode: uniqueCompanyCode
            });
            alert('Registration successful!');
            setForm({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                companyName: '',
                companyCode: '',
                size: '',
                date: '',
                state: '',
                occupation: '',
                message: '',
                password: '',
                confirmPassword: ''
            });
            setConsent(false);
        } catch (error) {
            console.error('Error adding document:', error);
            alert('Error submitting form. Please try again.');
        }
    };

    const handleLogin = () => {
        navigate('OwnerLogin');
    };
    const handleCreateNewUser = () => {
        navigate('/UserForm');
    };
    const handleDownload = () => {
        // Path to the file in the public folder
        const fileUrl = "/TechTrack.apk"; // Correct file path
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "TechTrack.apk"; // Name of the file when downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };





    return (
        <div className="MainPage">
            <div className="welcome">
                <div className='leftSide'>
                    <div className='annonce'>

                        <div>
                            <div className='appName'>TechTrack suite</div>
                            <div className='topannonce'>
                                <div className='appUse'>
                                    <div className='topann top1'> Job tracking - Time Management</div><br></br><div className='topann top2'> Chat Message</div> <br></br><div className='topann top3'>  Equipment inventory</div></div>

                            </div>
                        </div>
                        <div className="Images">
                            <img src={image5} alt="Description of Picture 1" className="Images1" />
                            <img src={image2} alt="Description of Picture 1" className="Images2" />

                        </div>


                    </div>

                    <br></br>
                </div>
                <div className='rightSide'>



                    <div>
                        <form id='schedule' className="schedule" onSubmit={handleSubmit}>
                            <div className='welcomText'> <span className='welcText'><span>Are you Company Contractor ? <p></p></span>Almost there!</span> Complete the form to create a company account and start your <span className='welcText'>free 60 days trial </span>today:</div>
                            <label>
                                * First Name:
                                <input type="text" name="firstname" value={form.firstname} onChange={handleChange} required />
                            </label>
                            <label>
                                * Last Name:
                                <input type="text" name="lastname" value={form.lastname} onChange={handleChange} required />
                            </label>
                            <label>
                                * Email Address:
                                <input type="email" name="email" value={form.email} onChange={handleChange} required />
                            </label>
                            <label>
                                * Company Name:
                                <input type="text" name="companyName" value={form.companyName} onChange={handleChange} required />
                            </label>
                            <label>
                                * Phone number : <br></br>Provide a Valid phone number we can call to verify within 48 hours.
                                <input
                                    type="tel"
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                    required
                                    placeholder="e.g., +1 980 123 4567"
                                    pattern="(\+1\s?\d{10}|\+?\d{1,2}\s?\(?\d{3}\)?\s?\d{3}[-\s]?\d{4}|\d{3}[-\s]?\d{3}[-\s]?\d{4})"
                                    title="Phone number must be in the format +1 9802495942, +19802495942, 980-249-5942, or +1 980-249-5942."
                                />
                            </label>

                            <label>
                                *
                                <select className="selectButon" name="size" value={form.size} onChange={handleChange} required>
                                    <option value="">Select company size</option>
                                    {companySizes.map((size) => (
                                        <option key={size} value={size}>{size}</option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                *
                                <select className="selectButon" name="state" value={form.state} onChange={handleChange} required>
                                    <option value="">Select your state</option>
                                    {states.map((state) => (
                                        <option key={state} value={state}>{state}</option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                * Occupation:
                                <input type="text" name="occupation" value={form.occupation} onChange={handleChange} />
                            </label>
                            <label>
                                * Password:
                                <input type="password" name="password" value={form.password} onChange={handleChange} required />
                            </label>
                            <label>
                                * Confirm Password:
                                <input type="password" name="confirmPassword" value={form.confirmPassword} onChange={handleChange} required />
                            </label>

                            <label className="consent">
                                <input className="check"
                                    type="checkbox"
                                    checked={consent}
                                    onChange={handleConsentChange}
                                    required
                                />
                                I agree to receive email communications and understand I can opt out at any time.
                            </label>
                            <button type="submit">Get 30 Days Free trial</button>

                            <p>Already have an account? <button type="button" onClick={handleLogin}>Login</button></p>
                        </form>
                    </div>

                </div>

            </div>

            <div className='annoncebot'>

                <h2>Why You Will Love TechTrack Suite</h2>
                <p>
                    TechTrackSuite is an innovative solution designed for{" "}
                    <strong>cable company contractors, HVAC technicians, electricians, painters, landscapers</strong>, and other construction or maintenance professionals. It addresses the unique operational challenges faced by{" "}
                    <strong>technicians, warehouse managers</strong>, and <strong>corporate teams</strong>.
                </p>
                <p>
                    Current corporate applications provided to contractors—such as{" "}
                    <strong>TechMobile for Spectrum, Cox App</strong>, or <strong>Xfinity App</strong>—offer limited functionalities, leaving critical gaps in areas like{" "}
                    <strong>job tracking, equipment management</strong>, and <strong>communication systems</strong>.
                </p>
                <p>
                    <strong>TechTrackSuite bridges these gaps</strong> with a fully integrated platform that streamlines:
                </p>
                
                    <li>Job recording</li>
                    <li>Inventory management</li>
                    <li>Equipment tracking</li>
                    <li>Real-time communication</li>
          
                <p>
                    This seamless and efficient workflow solution is <strong>more than just an app</strong>—it’s a productivity-enhancing platform designed to:
                </p>
              
                    <li>Empower technicians</li>
                    <li>Optimize warehouse operations</li>
                    <li>Provide managers with actionable insights</li>
              
                <p>
                    With TechTrackSuite, <strong>cable company contractors</strong> and other professionals can{" "}
                    <strong>save time, reduce costs</strong>, and <strong>enhance operational effectiveness</strong>, making it the ultimate tool for modern workforce management.
                </p>
            </div>
            <div class="download-section">
                <h2>Download Your Application</h2>
                <div className="pricingBox">
                    <h3>Pricing and Free Trial</h3>
                    <ul>
                        <li><strong>60 Days Free Trial</strong>: Try TechTrack risk-free!</li>
                        <li><strong>Flexible Pricing:</strong></li>
                        <ul>
                            <li><strong>$10/month per technician</strong> for companies with 10 or more technicians (includes all features).</li>
                            <li>
                                The <strong>Techtrack Warehouse app</strong> integrates seamlessly with the <strong>Warehouse Inventory Application </strong>, enabling real-time synchronization for efficient equipment management:
                                <ul>
                                    <li>Technicians receive real-time updates on all equipment received.</li>
                                    <li>The app displays equipment returned to the warehouse directly.</li>
                                    <li>
                                        Warehouse inventory is automatically updated with information about:
                                        <ul>
                                            <li>Equipment installed at client locations by technicians.</li>
                                            <li>Equipment transfers between technicians.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                    This suite includes all associated mobile applications, website applications, and desktop Windows Application for
                    inventory management.
                </p>
                <p>Elevate productivity and collaboration with one powerful, user-friendly tool.</p>

                        <li><strong>Free Cancellation:</strong> No obligations after the trial period.</li>
                    </ul>
                </div>
                

                <div class="version-boxes">
                    <div class="version-box">
                        <h2>Android</h2>
                        <h3>Field Technician Application</h3>
                        <p>Download the APK version for Android devices.</p>
                        <button onClick={handleDownload}>TechTrack Android</button>
                    </div>
                    <div class="version-box">
                        <h2>iOS</h2>
                        <h3>Field Technician Application</h3>
                        <p>Download the app for iOS devices.</p>
                        <button>Download iOS App</button>
                    </div>
                    <div class="version-box">
                        <h2>Windows</h2>
                        <h3>Warehouse Equipment Management</h3>
                        <p>Download the EXE version for Windows desktop.</p>
                        <button><a href="/TechTrackSetup.7z" download>Warehouse  Windows Pc  </a></button>
                    </div>
                </div>


            </div>
            <div className="whyTechTrack">


                





               



                <div>
                    <p>* * *</p>

                    <div className="fourBoxes">
                        <div className='leftBox'>

                            <div className='benefits'> Techtrack Mobile Easy to Train On & Use, designed with field techs in mind</div>
                            <div className='techboxes'>
                                <div className="pricingBox2 text">
                                    <strong>TEchTrack is easy to use, requiring minimal auto training </strong><br></br>
                                    Field technicians love using Techtrack because it makes it faster and easier to
                                    complete their work and  eliminates paperwork</div>
                                <img src={JobSum} alt="Equipment Inventory" className="boxImage" />
                                <img src={Newjob} alt="Equipment Inventory" className="boxImage" />
                                <img src={jobdash} alt="Equipment Inventory" className="boxImage" />
                                <img src={inventDash} alt="Equipment Inventory" className="boxImage" />
                                <img src={chat} alt="Equipment Inventory" className="boxImage" />

                                <div className='benefits'> Easy to Use and Benefits Warehouse</div>
                                <div className="pricingBox2 text">
                                    <strong>TechTrack Warehouse, TechTrack Web Dashboard, and TechTrack Mobile all
                                        operate on a unified data system</strong><br></br>Seamlessly connecting field technicians
                                    using mobile apps, warehouse staff working from desktop environments,
                                    and managers accessing the web dashboard. This shared, real-time data ensures
                                    that everyone stays aligned and informed,
                                    fostering collaboration and eliminating discrepancies across the team.
                                </div>
                                <img src={invent1} alt="Equipment Inventory" className="boxImage2" />
                                <img src={invent2} alt="Equipment Inventory" className="boxImage2" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='pricingBox' >

                    Are you a company or an individual with technical needs? Test the application
                    today by filling out the form <a href=".#schedule"> Create Free Account</a> get the user manual. <br></br>
                    Enjoy 24/7 assistance during training and throughout your 30-day free trial!

                </div>
            </div>
            <div className='challengeSol'>


<section className="challenges">
    <h3>Challenges in the Current System</h3>

    <h4>Technician Work Tracking</h4>
    <ul>
        <li>
            <strong>Limited Access to Job Records:</strong> Once a work order is
            completed and saved in the corporate application, technicians lose
            access to detailed records of past jobs.
        </li>
        <li>
            <strong>Manual Note-Keeping:</strong> Contractors often resort to
            handwritten notes to track completed jobs and estimate weekly or
            bi-weekly pay. This is time-consuming and prone to errors.
        </li>
    </ul>

    <h4>Equipment Inventory Management</h4>
    <ul>
        <li>
            <strong>Inaccurate Inventory Data:</strong> Corporate applications only
            show the status of uninstalled equipment, leading to issues like
            misplaced or misallocated equipment.
        </li>
        <li>
            <strong>Outdated Warehouse Management:</strong> Many warehouses rely on
            Excel sheets for equipment assignments and returns, which lacks
            real-time updates and accuracy.
        </li>
    </ul>

    <h4>Communication Gaps</h4>
    <ul>
        <li>
            Companies often rely on third-party applications for communication, leading
            to inefficiencies and scattered information.
        </li></ul>
</section>

<section className='solution'>
    <h3>Solutions Techtrack suite brings</h3>

    <h4>For Technicians</h4>
    <ul>
        <li>
            <strong>Streamlined Job Tracking: </strong>


            Quickly record job completion details, including job type, estimated
            pay, and equipment used, in just 30 seconds.

            Instantly generate a daily summary displaying the number of jobs
            completed and the total estimated pay.


        </li>
        <li>
            <strong>Accessible Job History:</strong> Technicians can access a
            detailed history of all completed jobs, eliminating the need for manual
            notes.
        </li>
        <li>
            <strong>Efficiency in Note-Taking:</strong> The mobile app saves
            significant time by automating job tracking and pay estimation, allowing
            technicians to focus on their core tasks.
        </li>
    </ul>

    <h4>For Warehouse Managers</h4>
    <ul>
        <li>
            <strong>Real-Time Inventory Updates:</strong> Automatically update
            inventory with every job, linking equipment serial numbers to job numbers
            and technician IDs.
        </li>
        <li>
            <strong>Enhanced Equipment Tracking:</strong> Search and filter equipment
            transactions to view detailed movements, including transfers between
            technicians or installations.
        </li>
    </ul>

    <h4>For Managers and Owners</h4>
    <ul>
        <li>
            <strong>Real-Time Insights:</strong> Access comprehensive reports on job
            completions, equipment usage, and technician performance in real-time.
        </li>
        <li>
            <strong>Improved Accountability:</strong> Ensure accurate tracking of
            equipment and job details without relying solely on corporate
            applications.
        </li>
    </ul>

    <h4>Integrated Communication</h4>
    <ul>
        <li>
            <strong>Built-In Messaging:</strong> An instant chat feature allows
            technicians, managers, and warehouse staff to communicate and share
            information without using external apps.
        </li>
    </ul>
</section>

<section className="avantage">
    <h4>TechTrackSuite Key Advantages </h4>

    <h4>Time-Saving Efficiency</h4>
    <ul>
        <li>Reduces time spent on manual note-keeping and inventory updates.</li>
        <li>Automates job tracking and pay estimation for technicians.</li>
    </ul>

    <h4>Accuracy and Transparency</h4>
    <ul>
        <li>
            Ensures precise tracking of job completions and equipment movements.
        </li>
        <li>
            Provides real-time data, reducing errors and discrepancies in inventory
            management.
        </li>
    </ul>

    <h4>Enhanced Productivity</h4>
    <ul>
        <li>
            Enables technicians to focus on their tasks by automating administrative
            burdens.
        </li>
        <li>
            Allows managers to make informed decisions based on real-time data.
        </li>
    </ul>

    <h4>Improved Communication</h4>
    <ul>
        <li>
            Integrated messaging fosters better collaboration and quicker problem
            resolution.
        </li>
    </ul>


</section>
</div>
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} TechTrack Suite. All Rights Reserved.</p>

                <p><strong>Contact:</strong> <a href="mailto:support@techtrack.com">support@techtrack.com</a></p>
                <p>Charlotte, North Carolina, USA 3244 Banbaurg Court, 28216 USA</p>
                <p>&copy; <span id="year"></span> TechTrack Suite. All rights reserved.</p>
                <p>Any usage, modification, or distribution of TechTrack and its suite of solutions without prior written approval from the Lead Tech Innovator is strictly prohibited.</p>
                <p>For permissions or inquiries, please contact: <strong>Owner:</strong> Papy Mulongo
                    <strong>Lead Tech Innovator:</strong> <a href="mailto:papayusmail@gmail.com">papayusmail@gmail.com</a>
                    <strong>Support:</strong> <a href="mailto:support@techtracsuite.com">support@techtracsuite.com</a></p>

                <ul className="footer-links">
                    <li><a href="/terms">Terms of Service</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </ul>

            </div>





        </div>
    );
}

export default Welcome;
