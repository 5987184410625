import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../backEnd/firebase';
import '../Styles/OwnerDashboard.css'; // Import the CSS file
const Inventory = ({ companyName }) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchInventoryData = async () => {
      try {
        const q = query(collection(db, 'InventoryData'), where('Company', '==', companyName));
        const querySnapshot = await getDocs(q);
        const inventoryList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInventoryData(inventoryList);
        setFilteredData(inventoryList);
        const emails = [...new Set(inventoryList.map(item => item.Email).filter(Boolean))];
        setEmailList(emails);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    };

    if (companyName) {
      fetchInventoryData();
    }
  }, [companyName]);

  const filterData = () => {
    let data = [...inventoryData];

    // Filter by date range
    if (startDate) {
      data = data.filter(item => new Date(item.Date) >= new Date(startDate));
    }
    if (endDate) {
      data = data.filter(item => new Date(item.Date) <= new Date(endDate));
    }

    // Filter by search term
    if (searchTerm) {
      data = data.filter(item =>
        item.CodeBare?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by selected email
    if (selectedEmail) {
      data = data.filter(item => item.Email === selectedEmail);
    }

    setFilteredData(data);
  };

  useEffect(() => {
    filterData();
  }, [searchTerm, startDate, endDate, selectedEmail]);

  return (
    <div className="MainPage">
      <h2>Equipment Analyses</h2>
<div className='EqAnalyse'>
      <div style={{ marginBottom: '1rem' }}>
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label style={{ marginLeft: '1rem' }}>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <label style={{ marginLeft: '1rem' }}>
          Search Equipment:
          <input
            type="text"
            placeholder="Enter equipment SN"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </label>
        <label style={{ marginLeft: '1rem' }}>
          Technician Email:
          <select
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.target.value)}
          >
            <option value="">All</option>
            {emailList.map((email, index) => (
              <option key={index} value={email}>
                {email}
              </option>
            ))}
          </select>
        </label>
      </div>
      </div>

      {filteredData.length > 0 ? (
        <table >
          <thead>
            <tr>
              <th>Date</th>
              <th>Equip SN</th>
              <th>Assigned</th>
              <th>Tech Email</th>
              <th>Returned</th>
              <th>Installed</th>
              <th>Job Num</th>
              <th>Transfert Out</th>
              <th>Transfert to</th>
              <th>Transfert In</th>
              <th>Transfert from</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td>{item.Date || ''}</td>
                <td>{item.CodeBare || ''}</td>
                <td>{item.Qty || ''}</td>
                <td>{item.Email || ''}</td>
                <td>{item.QtyReturn || ''}</td>
                <td>{item.QtyOut || ''}</td>
                <td>{item.jobNumber || ''}</td>
                <td>{item.QtyTO || ''}</td>
                <td>{item.TransfertTo || ''}</td>
                <td>{item.QtyTI || ''}</td>
                <td>{item.TransferFrom || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No inventory data found for the selected filters.</p>
      )}
    </div>
  );
};

export default Inventory;
