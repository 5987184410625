import React, { useState } from 'react';
import { getDocs, collection, updateDoc, query, where, doc } from 'firebase/firestore';
import { db } from '../backEnd/firebase';

function ForgotPassword() {
    const [form, setForm] = useState({
        email: '',
        firstname: '',
        companyName: '',
        newPassword: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        // Query Firestore to find a user matching the provided email, firstname, and companyName
        const usersRef = collection(db, 'Clients');
        const q = query(usersRef, 
            where('email', '==', form.email),
            where('firstname', '==', form.firstname),
            where('companyName', '==', form.companyName)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            setError('No matching user found. Please check your information and try again.');
            return;
        }

        // Update the user's password if a match is found
        try {
            const userDoc = querySnapshot.docs[0];
            const userRef = doc(db, 'Clients', userDoc.id);
            await updateDoc(userRef, { password: form.newPassword });
            setSuccess(true);
            setForm({
                email: '',
                firstname: '',
                companyName: '',
                newPassword: ''
            });
        } catch (error) {
            console.error('Error updating password:', error);
            setError('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="forgot-password">
            <h2>Forgot Password</h2>
            {success ? (
                <p>Password reset successfully! You can now log in with your new password.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <label>
                        Email:
                        <input type="email" name="email" value={form.email} onChange={handleChange} required />
                    </label>
                    <label>
                        First Name:
                        <input type="text" name="firstname" value={form.firstname} onChange={handleChange} required />
                    </label>
                    <label>
                        Company Name:
                        <input type="text" name="companyName" value={form.companyName} onChange={handleChange} required />
                    </label>
                    <label>
                        New Password:
                        <input type="password" name="newPassword" value={form.newPassword} onChange={handleChange} required />
                    </label>
                    <button type="submit">Reset Password</button>
                </form>
            )}
            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default ForgotPassword;
